const ethMain = "Ethereum Mainnet";
const ropsten = "Ropsten (Ethereum Testnet)";
const rinkeby = "Rinkeby (Ethereum Testnet)";
const goerli = "Goerli (Ethereum Testnet)";
const kovan = "Kovan (Ethereum Testnet)";
const bsc = "Binance Smart Chain Mainnet";
const bscTestnet = "Binance Smart Chain Testnet";
const polygon = "Polygon (Matic) Mainnet";
const polygonTestnet = "Mumbai (Matic Testnet)";
const avalanche = "Avalanche Mainnet";
const local = "Local Dev Chain (Ganache, Hardhat)";

export type ChainId = "eth" | "0x1" | "ropsten" | "0x3" | "rinkeby" | "0x4" | "goerli" | "0x5" | "kovan" | "0x2a" | "polygon" | "0x89" | "mumbai" | "0x13881" | "bsc" | "0x38" | "bsc testnet" | "0x61" | "avalanche" | "0xa86a" | undefined;

const ETH = "ETH";
const BNB = "BNB";
const MATIC = "MATIC";
const AVAX = "AVAX";

const etherscan = "https://etherscan.io/";
const cChainExplorer ="https://cchain.explorer.avax.network/";
const polygonscan = "https://polygonscan.com/";
const bscScan = "https://bscscan.com/";

export const Explorers: { [key: string]: string } = {
  "eth": etherscan, 
  'mainnet': etherscan,
  '0x1': etherscan,
  'testnet': '',
  'ropsten': '',
  '0x3': '',
  'rinkeby': '',
  '0x4': '',
  'goerli': '', 
  '0x5': '',
  'kovan': '', 
  '0x2a': '',
  'bsc': bscScan, 
  'binance': bscScan ,
  'binance smart chain': bscScan,
  '0x38': bscScan,
  'bsc testnet': '', 
  'binance testnet': '', 
  'binance smart chain testnet': '', 
  '0x61': '', 
  'matic': polygonscan,
  'polygon': polygonscan,
  '0x89': polygonscan,
  'mumbai': '',
  'matic testnet': '',
  'polygon testnet': '',
  '0x13881': '',
  'avalanche': cChainExplorer,
  '0xa86a': cChainExplorer,
  'ganache': '',
  'hardhat': '',
  'localdevchain': '',
  'local': '',
  'devchain': '',
  'dev': '',
  '0x539': '',
}

export const Symbols: { [key: string ]: string } = { 
    [ethMain]: ETH,
    [ropsten]: ETH,
    [rinkeby]: ETH,
    [goerli]: ETH,
    [kovan]: ETH,
    [bsc]: BNB,
    [bscTestnet]: BNB,
    [polygon]: MATIC,
    [polygonTestnet]: MATIC,
    [avalanche]: AVAX,
    [local]: ETH,
}

const chains: { [key: string]: string } = {
    "eth": ethMain, 
    'mainnet': ethMain,
    '0x1': ethMain,
    'testnet': ropsten,
    'ropsten': ropsten,
    '0x3': ropsten,
    'rinkeby': rinkeby,
    '0x4': rinkeby,
    'goerli': goerli, 
    '0x5': goerli,
    'kovan': kovan, 
    '0x2a': kovan,
    'bsc': bsc, 
    'binance': bsc ,
    'binance smart chain': bsc,
    '0x38': bsc,
    'bsc testnet': bscTestnet, 
    'binance testnet': bscTestnet, 
    'binance smart chain testnet': bscTestnet, 
    '0x61': bscTestnet, 
    'matic': polygon,
    'polygon': polygon,
    '0x89': polygon,
    'mumbai': polygonTestnet,
    'matic testnet': polygonTestnet,
    'polygon testnet': polygonTestnet,
    '0x13881': polygonTestnet,
    'avalanche': avalanche,
    '0xa86a': avalanche,
    'ganache': local,
    'hardhat': local,
    'localdevchain': local,
    'local': local,
    'devchain': local,
    'dev': local,
    '0x539': local,
}

export default chains;
