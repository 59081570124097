import { useCallback, useEffect, useReducer } from "react";
import { createContainer } from "unstated-next";
import { StoreEvent, Dispatch } from "./types";
import produce from "immer";
import { useMoralis } from "react-moralis";
import { useContainer as useAuth } from "./auth";

export interface Transaction {
  /** The hash of the transaction */
  hash: string;
  /** The nonce of the transaction */
  nonce: string;
  /** The transaction index */
  transaction_index: string;
  /** The sender */
  from_address: string;
  /** The recipient */
  to_address: string;
  /** The value that was transfered (in wei) */
  value: string;
  /** The gas of the transaction */
  gas: string;
  /** The gas price */
  gas_price: string;
  /** The input */
  input: string;
  /** The receipt cumulative gas used */
  receipt_cumulative_gas_used: string;
  /** The receipt gas used */
  receipt_gas_used: string;
  /** The receipt contract address */
  receipt_contract_address: string;
  /** The receipt root */
  receipt_root: string;
  /** The receipt status */
  receipt_status: string;
  /** The block timestamp */
  block_timestamp: string;
  /** The block number */
  block_number: string;
  /** The block hash */
  block_hash: string;
}

interface TransactionResponse {
  total?: number;
  /** The page of the current result */
  page?: number;
  /** The number of results per page */
  page_size?: number;
  result?: Transaction[];
}

interface State {
  transactions: Transaction[];
}

enum TransactionsActionTypes {
  reset = "RESET",
  setTransactions = "SET_TRANSACTIONS",
}

interface TransactionsStore extends State {
  dispatch: Dispatch<TransactionsActionTypes>;
}

export const storeKey = "auth";
export const storeVersion = 1;
export const migrations = [];

const INITIAL_STATE: State = {
  transactions: [],
};

const reducer = (state: State, action: StoreEvent<TransactionsActionTypes>) => {
  const { type, payload } = action;
  switch (type) {
    case TransactionsActionTypes.setTransactions:
      return produce(state, (draft) => {
        draft.transactions = payload;
      });
    case TransactionsActionTypes.reset:
      return produce(state, (draft) => {
        draft.transactions = INITIAL_STATE.transactions;
      });
    default:
      return state;
  }
};

const useTransactionsStore = (): TransactionsStore => {
  const auth = useAuth();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { Moralis, isAuthenticated } = useMoralis();

  const getTransactions = useCallback(
    () => {
      Moralis.Web3API.account
        .getTransactions({
          chain: auth.chainId,
          address: auth.address,
        })
        .then((transactions: TransactionResponse) => {
          dispatch({
            type: TransactionsActionTypes.setTransactions,
            payload: transactions.result,
          });
        });
    },
    [Moralis.Web3API.account, auth.address, auth.chainId]
  );

  useEffect(() => {
    if (isAuthenticated && auth.chainId) {
      getTransactions();
    }
  }, [isAuthenticated, getTransactions, auth.chainId]);

  return {
    ...state,
    dispatch,
  };
};

const container = createContainer(useTransactionsStore);
export const { Provider, useContainer } = container;

export default container;
