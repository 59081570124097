import { Box, Button, SimpleGrid, Flex, Heading, Text } from "@chakra-ui/react";
import NFTCard from "./NFTCard";
import { useContainer as useAuth } from "../store/auth";
import { useContainer as useTokens } from "../store/tokens";

const NFTList = () => {
  const { isAuthenticated } = useAuth();
  const { nfts, loadNfts } = useTokens();
  // useEffect(() => {
  //   console.log(isAuthenticated, nfts.result.length);
  //   if (isAuthenticated && nfts.result.length === 0) {
  //     console.log('loadNFTS?');
  //     loadNfts();
  //   }
  //   return () => {}
  // }, [isAuthenticated])
  return (
    <>
      {isAuthenticated && nfts.result.length === 0 && (
        <Flex
          direction="column"
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Heading
            textAlign="center"
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
            py={{ base: "20", md: "50" }}
          >
            <Text as={"h3"} position={"relative"}>
              Whoops! No NFTs found...
            </Text>
          </Heading>
          <Box>
            <Button
              onClick={loadNfts}
              bg="blue.800"
              color="blue.300"
              fontSize="lg"
              fontWeight="medium"
              borderRadius="xl"
              border="1px solid transparent"
              _hover={{
                borderColor: "blue.700",
                color: "blue.400",
              }}
              _active={{
                backgroundColor: "blue.800",
                borderColor: "blue.700",
              }}
            >
              Refresh NFTs
            </Button>
          </Box>
        </Flex>
      )}
      <SimpleGrid columns={{ sm: 1, md: 3 }} spacing="40px">
        {nfts.result.map((nft, i) => (
          <NFTCard key={`${nft.token_address}_${i}`} nft={nft} />
        ))}
      </SimpleGrid>
    </>
  );
};

export default NFTList;
