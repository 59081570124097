import { useEffect, useCallback, useReducer } from 'react';
import { createContainer } from 'unstated-next';
import { StoreEvent, Dispatch } from './types';
import produce from 'immer';
import Moralis from 'moralis/types';
import { useMoralis } from "react-moralis";
import chains, { Symbols } from '../constants/chains';
import { BigNumber } from 'ethers';
import { useContainer as useAuth } from './auth';

interface Balance extends Moralis.ERC20Result {
  display: string;
}

interface NFTMap {
  page: number;
  page_size: number;
  result: any[],
  status: string;
  total: number;
}

interface State {
  balance: Balance | null;
  balances: Balance[] | null
  nfts: NFTMap,
}

enum AuthActionTypes {
  setBalance = 'SET_BALANCE',
  setBalances = 'SET_BALANCES',
  setNFTs = 'SET_NFTS',
  reset = 'RESET',
}

interface AuthStore extends State {
  dispatch: Dispatch<AuthActionTypes>;
  reset: () => void;
  loadBalances: () => void;
  loadNfts: () => void;
  getTokenIdMetadata: (address: string) => void;
  getTokenMetadataJson: (nft: any) => Promise<any>;
  getTokenMetadataBySymbol: (address: string) => void;
  getTokenPrice: (address: string) => void;
  renderBalance: (value: string, decimals: string) => string;
  renderGas: (gas: string, gasPrice: string) => string;
}

export const storeKey = 'tokens';
export const storeVersion = 1;
export const migrations = [];

const INITIAL_STATE: State = {
  balance: null,
  balances: [],
  nfts: {
    page: 0,
    page_size: 500,
    result: [],
    status: "LOADING",
    total: 0,
  } 
}

const reducer = (state: State, action: StoreEvent<AuthActionTypes>) => {
  const { type, payload } = action;
  switch (type) {
    case AuthActionTypes.setBalance:
      return produce(state, draft => {
        draft.balance = payload;
      });
    case AuthActionTypes.setBalances:
      return produce(state, draft => {
        draft.balances = payload;
      });
    case AuthActionTypes.setNFTs:
      return produce(state, draft => {
        draft.nfts = payload;
      });
    case AuthActionTypes.reset:
      return produce(state, draft => {
        draft.balance = INITIAL_STATE.balance;
        draft.balances = INITIAL_STATE.balances;
        draft.nfts = INITIAL_STATE.nfts;
      });
    default:
      return state;
  }
};

const useAuthStore = (): AuthStore => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { address, chainId, chainName, chainSymbol } = useAuth();
  const { Moralis } = useMoralis();
  const web3 = new Moralis.Web3();

  const getTokenIdMetadata = ({ token_address, token_id }: any) => {
    if (!token_address || !token_id) return;
    Moralis.Web3API.token
    .getTokenIdMetadata({ chain: chainId, address: token_address, token_id })
    .then((metadata) => {
      // console.log(metadata);
    })
  }

  // const getTokenMetadata = (address?: string) => {
  //   const options = { chain: chainId, address };
  //   Moralis.Web3API.token
  //     .getTokenMetadataBySymbol(options)
  //     .then(console.log)
  //     .catch(console.log);
  // }

  const getTokenMetadataBySymbol = (symbol?: string) => {
    const options = { chain: chainId, symbols: ["ETH"] };
    Moralis.Web3API.token
      .getTokenMetadataBySymbol(options)
      .then(console.log)
      .catch(console.log);
  }
  
  const getTokenMetadataJson = ({ token_uri }: any): Promise<any> => {
    return Moralis.Cloud.run("fetchJSON", { url: token_uri, });
  }

  const getTokenPrice = (address: string) => {
    //Get token price on PancakeSwap v2 BSC
    const options = {
      address,
      chain: chainId,
      // exchange: "PancakeSwapv2"
    };
    Moralis.Web3API.token
      .getTokenPrice(options)
      .then(console.log)
      .catch(console.log);
  }

  const loadBalances = useCallback(() => {
    const options = { 
      address: address || '',
      chain: chainId,
    };
    Moralis.Web3API.account.getNativeBalance(options).then((balance) => {
      const balanceDisplay = web3.utils.fromWei(balance.balance, "ether");
      const cName = chainName || chains['eth'];
      const cSymbol = chainSymbol || Symbols[cName];
      dispatch({
        type: AuthActionTypes.setBalance, payload: {
          balance,
          balanceDisplay,
          display: `${parseFloat(balanceDisplay).toFixed(4)} ${cSymbol}`,
        }
      });
    });
    Moralis.Web3API.account.getTokenBalances(options).then((balances) => {
      dispatch({
        type: AuthActionTypes.setBalances, payload: balances
      });
    });
  }, [address, chainId, Moralis.Web3API.account, web3.utils, chainName, chainSymbol])

  const loadNfts = useCallback(() => {
    const options = { 
      address: address || '',
      chain: chainId,
    };
    Moralis.Web3API.account.getNFTs(options).then((nfts) => {
      dispatch({
        type: AuthActionTypes.setNFTs, payload: nfts
      });
    }); 
  }, [Moralis.Web3API.account, chainId, address]);

  useEffect(() => {
    if(chainId) {
      loadNfts();
      loadBalances();
    }
  }, [chainId, loadBalances, loadNfts]);

  const reset = () => dispatch({
    type: AuthActionTypes.reset,
    payload: {}
  });

  const renderBalance = (value: string, decimals: string) => {
    const bn = web3.utils.fromWei(value);
    return parseFloat(bn).toFixed(8);
  }

  const renderGas = (gas: string, gasPrice: string) => {
    const value = BigNumber.from(gas).mul(gasPrice).toString();
    const bn = web3.utils.fromWei(value);
    return parseFloat(bn).toFixed(8);
  }

  console.log(state);

  return {
    ...state,
    dispatch,
    reset,
    loadNfts,
    loadBalances,
    getTokenIdMetadata,
    getTokenMetadataBySymbol,
    getTokenMetadataJson,
    getTokenPrice,
    renderBalance,
    renderGas,
  };
};

const container = createContainer(useAuthStore);
export const { Provider, useContainer } = container;

export default container;
