import { useEffect, useReducer } from 'react';
import { createContainer } from 'unstated-next';
import { StoreEvent, Dispatch } from './types';
import produce from 'immer';
import Moralis from 'moralis/types';
import { useMoralis } from "react-moralis";
import chains, { Symbols, ChainId, Explorers } from '../constants/chains';
import { abbrevAddress } from '../utils';

interface State {
  user: Moralis.User<Moralis.Attributes> | null;
  chainId: ChainId;
  chainSymbol: string;
  chainName: string | null;
  chainExplorer: string | null;
  address: string;
  abbrevAddress: string | null;
}

enum AuthActionTypes {
  reset = 'RESET',
  setUser = 'SET_USER',
  setBalance = 'SET_BALANCE',
  setBalances = 'SET_BALANCES',
  setChainId = 'SET_CHAIN_ID',
  setAddress = 'SET_ADDRESS',
  setNFTs = 'SET_NFTS',
}

interface AuthStore extends State {
  dispatch: Dispatch<AuthActionTypes>;
  isAuthenticated: boolean;
  login: () => void;
  loginWalletconnect: () => void;
  logout: () => void;
  reset: () => void;
}

export const storeKey = 'auth';
export const storeVersion = 1;
export const migrations = [];

const INITIAL_STATE: State = {
  user: null,
  chainId: undefined,
  chainName: null,
  chainSymbol: '',
  chainExplorer: '',
  address: '',
  abbrevAddress: null,
}

const reducer = (state: State, action: StoreEvent<AuthActionTypes>) => {
  const { type, payload } = action;
  switch (type) {
    case AuthActionTypes.setUser:
      return produce(state, draft => {
        draft.user = payload;
      });
    case AuthActionTypes.setChainId:
      return produce(state, draft => {
        const chainName = chains[payload];
        draft.chainId = payload;
        draft.chainName = chainName;
        draft.chainSymbol = Symbols[chainName];
        draft.chainExplorer = Explorers[payload];
      });
    case AuthActionTypes.setAddress:
      return produce(state, draft => {
        draft.address = payload;
        draft.abbrevAddress = abbrevAddress(payload);
      });
    case AuthActionTypes.reset:
      return produce(state, draft => {
        draft.user = INITIAL_STATE.user;
        draft.chainName = INITIAL_STATE.chainName;
        draft.address = INITIAL_STATE.address;
      });
    default:
      return state;
  }
};

const useAuthStore = (): AuthStore => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const moralisInstance = useMoralis();
  const { Moralis, authenticate, isAuthenticated, user, logout, isWeb3Enabled, enableWeb3 } = moralisInstance;
  const web3 = new Moralis.Web3();
  
  const { chainId } = state;

  useEffect(() => {
    if (!isWeb3Enabled && isAuthenticated) {
      enableWeb3();
    }
    if (isWeb3Enabled) {
      dispatch({
        type: AuthActionTypes.setChainId, payload: (window as any).ethereum.chainId
      });
    }
  }, [isWeb3Enabled, isAuthenticated, enableWeb3]);

  useEffect(() => {
    if (chainId) {
      dispatch({
        type: AuthActionTypes.setChainId, payload: chainId
      });
    }
    if (user) {
      if (!state.user) {
        dispatch({
          type: AuthActionTypes.setUser, payload: user
        });
      }
      dispatch({
        type: AuthActionTypes.setAddress, payload: user.get("ethAddress")
      });
    }
  }, [state.chainId, Moralis.Web3, Moralis.Web3API.account, state.address, state.user, user, web3.utils, chainId]);

  useEffect(() => {
    const unsubscribe: any = Moralis.Web3.onChainChanged((chain: any) => {
      dispatch({
        type: AuthActionTypes.setChainId, payload: chain
      });
    });
    return () => {
      unsubscribe()
    }
  }, [Moralis.Web3]);

  const reset = () => dispatch({
    type: AuthActionTypes.reset,
    payload: {}
  });

  const handleLogin = () => {
    authenticate({ signingMessage: "NFT Sniper Authentication" });
  };

  const handleLoginWalletConnect = () => {
    authenticate({ 
      chainId: 1,
      provider: "walletconnect",
      signingMessage: "Dapp Dashboard"
    });
  };

  const handleLogout = () => {
    logout();
  };

  return {
    ...state,
    dispatch,
    isAuthenticated,
    login: handleLogin,
    loginWalletconnect: handleLoginWalletConnect,
    logout: handleLogout,
    reset,
  };
};

const container = createContainer(useAuthStore);
export const { Provider, useContainer } = container;

export default container;
