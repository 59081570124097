import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import "@fontsource/inconsolata";

import { MoralisProvider } from "react-moralis";
import { Provider as AuthProvider } from "./store/auth";
import { Provider as TokensProvider } from "./store/tokens";
import { Provider as TransactionsProvider } from "./store/transactions";

import Layout from "./components/Layout";
import Landing from "./components/Landing";
import Transactions from "./components/Transactions";
import WalletManager from "./components/WalletManager";
import NFTList from "./components/NFTList";

const APP_ID = "SfC4YVBKeHxCoMFXXHnOysOAgk6wn0wL95ZDvNwA";
const SERVER_URL = "https://8dl7wynk6wta.grandmoralis.com:2053/server";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
        <AuthProvider>
          <TokensProvider>
            <TransactionsProvider>
              <Router>
                <Switch>
                  <Layout>
                    <Route path="/" exact>
                      <Landing />
                    </Route>
                    <Route path="/transactions">
                      <Transactions />
                    </Route>
                    <Route path="/nfts">
                      <NFTList />
                    </Route>
                    <Route path="/wallets">
                      <WalletManager />
                    </Route>
                  </Layout>
                </Switch>
              </Router>
            </TransactionsProvider>
          </TokensProvider>
        </AuthProvider>
      </MoralisProvider>
    </ChakraProvider>
  );
}

export default App;
