import { ReactText } from "react";

import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  BoxProps,
  FlexProps,
} from "@chakra-ui/react";

import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
} from "react-icons/fi";

import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";

import { useContainer as useAuth } from "../store/auth";

interface LinkItemProps {
  name: string;
  icon: IconType;
  to: string;
  auth?: boolean;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Home", icon: FiHome, to: "/" },
  { name: "NFTs", icon: FiTrendingUp, to: "/nfts", auth: true },
  { name: "Transactions", icon: FiCompass, to: "/transactions", auth: true },
  { name: "Wallets", icon: FiStar, to: "/wallets", auth: true },
  { name: "Settings", icon: FiSettings, to: "/settings", auth: true },
];

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { isAuthenticated } = useAuth();

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          <NavLink exact activeClassName="active" to="/">
            [DAPP]
          </NavLink>
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.filter((l) => (l.auth === true ? isAuthenticated : true)).map(
        (link) => (
          <NavItem key={link.name} icon={link.icon} to={link.to}>
            {link.name}
          </NavItem>
        )
      )}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  to: string;
  icon: IconType;
  children: ReactText;
}
const NavItem = ({ to, icon, children, ...rest }: NavItemProps) => {
  return (
    <NavLink href="#" style={{ textDecoration: "none" }} to={to}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </NavLink>
  );
};

export default SidebarContent;
