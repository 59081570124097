import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
} from "@chakra-ui/react";

import { useContainer as useAuth } from '../store/auth';
import { useContainer as useTokens } from "../store/tokens";
import { Transaction, useContainer as useTransactions } from '../store/transactions';
import { abbrevAddress, renderTimestamp } from '../utils';

const Transactions = () => {
  const auth = useAuth();
  const tokens = useTokens();
  const transactions = useTransactions();
  const { chainSymbol } = auth;
  return (
    <Table variant="striped">
      <Thead>
        <Tr>
          <Th>nonce</Th>
          <Th>block_timestamp</Th>
          <Th>hash</Th>
          <Th>from_address</Th>
          <Th>to_address</Th>
          <Th />
          <Th>{`Value (${chainSymbol})`}</Th>
          <Th>{`Tx Fee (${chainSymbol})`}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {transactions.transactions.map((transaction: Transaction) => {
          const {
            block_timestamp, 
            hash, 
            nonce, 
            from_address, 
            to_address,
            value,
            gas,
            gas_price,
          } = transaction;
          
          const isSend = from_address === auth.address;

          return (
            <Tr key={hash}>
              <Td>{nonce}</Td>
              <Td>{renderTimestamp(block_timestamp)}</Td>
              <Td>{abbrevAddress(hash)}</Td>
              <Td>{abbrevAddress(from_address)}</Td>
              <Td>{abbrevAddress(to_address)}</Td>
              <Td>
                <Badge colorScheme={(isSend) ? "red" : "green"}>
                  {
                    (isSend) ? "SEND" : "RECEIVE"
                  }
                </Badge>
              </Td>
              <Td>{tokens.renderBalance(value, "18")}</Td>
              <Td>{tokens.renderGas(gas, gas_price)}</Td>
            </Tr>
          )  
        })}
      </Tbody>
    </Table>
  );
};

export default Transactions;
