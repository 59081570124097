import Hero from "./Hero";
import Hero2 from "./Hero2";

const Landing = () => {
  return (
    <>
      <Hero />
      <Hero2 />
    </>
  );
};

export default Landing;
