import { useEffect, useState } from 'react';
import {
  Flex,
  Box,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";

import { useContainer as useTokens } from "../store/tokens";
import defaultImage from '../images/default-img.jpg';

interface NFTCardProps {
  nft: any;
}

function NFTCard({ nft }: NFTCardProps) {
  let m: any;
  try {
    m = JSON.parse(nft.metaData);
  } catch (err) {
    m = null;
  }
  const [tokenId, setTokenId] = useState(nft.token_id);
  const [metaData, setMetaData] = useState(m);
  const { getTokenMetadataJson } = useTokens();
  const nftName = `${nft.name} #${nft.token_id}`;

  useEffect(() => {
    if (!metaData) {
      getTokenMetadataJson(nft).then(metaData => {
        if (metaData) {
          setMetaData({ 
            ...metaData,
            image: metaData.image.replace('ipfs://', 'https://ipfs.moralis.io:2053/ipfs/')
          });
        }
      })
    }
    return () => {}
  }, [getTokenMetadataJson, metaData, nft]);

  useEffect(() => {
    if (nft.token_id !== tokenId) {
      setTokenId(nft.token_id);
      setMetaData(nft.metaData);
    }
    return () => {}
  }, [nft.metaData, nft.token_id, tokenId]);

  return (
    <Flex p={1} w="full" alignItems="center" justifyContent="center">
      <Box
        bg={useColorModeValue("white", "gray.800")}
        maxW="sm"
        minW="sm"
        minH="300px"
        borderWidth="1px"
        rounded="lg"
        shadow="lg"
        position="relative"
      >
        <Image
          src={(metaData && metaData.image) || defaultImage}
          alt={nftName}
          roundedTop="lg"
        />

        <Box p="6">
          <Flex mt="1" justifyContent="space-between" alignContent="center">
            <Box
              fontSize="2xl"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
              textAlign="center"
              width="100%"
            >
              {nftName}
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}

export default NFTCard;
