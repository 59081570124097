import { ReactNode } from "react";

import {
  Box,
  Flex,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Link,
  HStack,
  IconButton,
  FlexProps,
} from "@chakra-ui/react";

import { FiMenu } from "react-icons/fi";

import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { ExternalLinkIcon, CopyIcon } from "@chakra-ui/icons";
import { useContainer as useAuth } from "../store/auth";
import { useContainer as useTokens } from "../store/tokens";
import { NavLink } from "react-router-dom";
import Identicon from "./Identicon";

const Links = ["NFTs", "Transactions", "Wallets"];

const NavMenuLink = ({ children, to }: { children: ReactNode; to: string }) => (
  <Box
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
  >
    <NavLink to={to.toLowerCase()}>{children}</NavLink>
  </Box>
);

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const {
    isAuthenticated,
    login,
    logout,
    abbrevAddress,
    chainName,
    chainExplorer,
    address,
  } = useAuth();

  const { balance } = useTokens();
  const { colorMode, toggleColorMode } = useColorMode();
  // const bgColor = useColorModeValue("gray.100", "gray.900");
  const textColor = useColorModeValue("gray.900", "white");

  return (
    <Flex
      color={textColor}
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        <NavLink exact activeClassName="active" to="/">
          [DAPP]
        </NavLink>
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        {/* <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        /> */}
        {!isAuthenticated && (
          <Button
            onClick={login}
            bg="blue.800"
            color="blue.300"
            fontSize="lg"
            fontWeight="medium"
            borderRadius="xl"
            border="1px solid transparent"
            _hover={{
              borderColor: "blue.700",
              color: "blue.400",
            }}
            _active={{
              backgroundColor: "blue.800",
              borderColor: "blue.700",
            }}
          >
            Connect to a wallet
          </Button>
        )}
        {isAuthenticated && (
          <Flex alignItems={"center"}>
            <Menu matchWidth>
              <MenuButton
                flexDirection="row"
                as={Box}
                variant={"link"}
                cursor={"pointer"}
              >
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box px="3" display={{ base: "none", md: "flex" }}>
                    <Text fontSize="md">{chainName}</Text>
                  </Box>
                  <Box px="3">
                    <Text fontSize="md">{balance && balance.display}</Text>
                  </Box>
                  <Text
                    display={{ base: "none", md: "flex" }}
                    fontSize="md"
                    fontWeight="medium"
                    mr="2"
                  >
                    {abbrevAddress}
                  </Text>
                  <Identicon size={36} />
                </Box>
              </MenuButton>
              <MenuList alignItems={"center"}>
                <br />
                <Center>
                  <Identicon size={128} />
                </Center>
                <br />
                <Center mb="3" display={{ base: "flex", md: "none" }}>
                  {chainName}
                </Center>
                <Center mb="3">
                  <p>{abbrevAddress}</p>
                </Center>
                <Center>
                  <Button onClick={toggleColorMode}>
                    {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                  </Button>
                </Center>
                <Box px={5} pt={4} pb={2} mb={3}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb={3}
                  >
                    <Text color="gray.400" fontSize="sm">
                      Connected with MetaMask
                    </Text>
                    <Button
                      variant="outline"
                      size="sm"
                      borderColor="blue.800"
                      borderRadius="3xl"
                      color="blue.500"
                      fontSize="13px"
                      fontWeight="normal"
                      px={2}
                      height="26px"
                      _hover={{
                        background: "none",
                        borderColor: "blue.300",
                        textDecoration: "underline",
                      }}
                      onClick={logout}
                    >
                      Disconnect
                    </Button>
                  </Flex>
                  <Flex alignContent="center" m={3}>
                    <Button
                      variant="link"
                      color="gray.400"
                      fontWeight="normal"
                      fontSize="sm"
                      _hover={{
                        textDecoration: "none",
                        color: "whiteAlpha.800",
                      }}
                    >
                      <CopyIcon mr={1} />
                      Copy Address
                    </Button>
                    <Link
                      fontSize="sm"
                      display="flex"
                      alignItems="center"
                      href={`${chainExplorer}address/${address}`}
                      isExternal
                      color="gray.400"
                      ml={6}
                      _hover={{
                        color: "whiteAlpha.800",
                        textDecoration: "underline",
                      }}
                    >
                      <ExternalLinkIcon mr={1} />
                      View on Explorer
                    </Link>
                  </Flex>
                </Box>
                <MenuDivider />
                <MenuItem textAlign="center">
                  <NavLink
                    style={{ width: "100%" }}
                    exact
                    activeClassName="active"
                    to="/"
                    onClick={logout}
                  >
                    Logout
                  </NavLink>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        )}
      </HStack>
    </Flex>
  );
};

export default MobileNav;
