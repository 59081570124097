import { useEffect, useRef } from "react";
import Jazzicon from "@metamask/jazzicon";
import { useContainer as useAuth } from '../store/auth';
import { Circle } from "@chakra-ui/react";

export default function Identicon({ size }: { size?: number }) {
  const ref = useRef<HTMLDivElement>();
  const { address } = useAuth();
  const width = size || 16;

  useEffect(() => {
    if (address && ref.current) {
      ref.current.innerHTML = "";
      ref.current.appendChild(Jazzicon(width, parseInt(address.slice(2, 10), 16)));
    }
  }, [address, width]);

  return <Circle maxWidth={width} ref={ref as any} />;
}
